import Axios, { AxiosResponse } from 'axios';
import { IResponseSchedule, IResponseSettings } from '../../Types';

Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=utf-8';

// interface IResponse
const API_URL = 'https://uaifm.com.br/';
const VERSION_URL = 'api/v1/';
const BASE_URL = API_URL + VERSION_URL;

const API = {
  settings: async (): Promise<IResponseSettings | null> => {
    try {
      const PARAMS = `?dev&web`;
      const response: AxiosResponse = await Axios.get(
        `${BASE_URL}settings.php${PARAMS}`,
      );
      return response.data as IResponseSettings;
    } catch (error) {
      return null;
    }
  },
  schedule: async (): Promise<IResponseSchedule | null> => {
    try {
      const response: AxiosResponse = await Axios.get(
        `${BASE_URL}schedules.php`,
      );
      return response.data as IResponseSchedule;
    } catch (error) {
      return null;
    }
  },
};
export default API;
