// Modules
import styled, { keyframes } from 'styled-components';

// Iocns
import { FaGooglePlay } from 'react-icons/fa';
import { MdFacebook } from 'react-icons/md';
import { RiInstagramFill, RiWhatsappFill } from 'react-icons/ri';

// Styles
import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

// Assets
import BACKGROUND_IMG from '../../assets/wallpaper.jpg';

export const ContainerMobile = styled.div`
  background-image: url(${BACKGROUND_IMG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  color: ${colors.white};
`;

export const AvatarContainerMobile = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.primary};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AvatarInfoMobile = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  background-color: ${colors.primary};
`;

export const InfoMobile = styled.div`
  display: flex;
  flex: 1;
  margin: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingCircleMobile = styled.div`
  display: inline-block;
  border: 4px solid ${colors.primaryLight};
  border-top: 4px solid ${colors.secondary};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spinAnimation} 1s linear infinite;
`;

export const TextMobile = styled.h2`
  font-family: ${fonts.Roboto};
  font-weight: 900;
  text-align: center;
  color: ${colors.secondaryLight};
`;

export const SubTextMobile = styled.h1`
  font-family: ${fonts.Roboto};
  font-weight: lighter;
  text-align: center;
  color: ${colors.white};
`;

export const SocialMobile = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${colors.secondary};
`;

export const IconWhatsappMobile = styled(RiWhatsappFill).attrs({ size: 32 })`
  color: ${colors.primary};
  margin-right: 16px;
  transition: transform 0.3s ease;
`;

export const IconFacebookMobile = styled(MdFacebook).attrs({ size: 32 })`
  color: ${colors.primary};
  margin-right: 16px;
  transition: transform 0.5s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;
export const IconInstagramMobile = styled(RiInstagramFill).attrs({ size: 32 })`
  color: ${colors.primary};
  margin-right: 16px;
  transition: transform 0.5s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;
export const IconGooglePlayMobile = styled(FaGooglePlay).attrs({ size: 32 })`
  color: ${colors.primary};
  margin-right: 16px;
  transition: transform 0.5s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const BodyMobile = styled.div`
  height: 75vh;
  display: flex;
`;
