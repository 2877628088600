// Modules
import styled, { keyframes } from 'styled-components';

// Icons
import { FaGooglePlay } from 'react-icons/fa';
import { MdFacebook } from 'react-icons/md';
import { RiInstagramFill, RiWhatsappFill } from 'react-icons/ri';

// Styles
import colors from '../../styles/colors';
import fonts from '../../styles/fonts';

// Assets
import BACKGROUND_IMG from '../../assets/wallpaper.jpg';
import WAVES_IMG from '../../assets/wavesOpacity.svg';

export const Container = styled.div`
  background-image: url(${BACKGROUND_IMG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${colors.white};
`;

export const Body = styled.div`
  height: 75vh;
  display: flex;
`;

export const BodyRight = styled.div`
  height: 40vh;
  flex: 0.65;
  margin: 16px 0;
  display: flex;
  align-items: center;
`;

export const PictureContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 100%;
  position: absolute;
  display: flex;
  background-color: ${colors.primary};
  justify-content: center;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.primary};
  flex-direction: column;
  margin-left: 100px;
`;

export const Avatar = styled.img`
  width: 185px;
  height: 185px;
  border-radius: 100%;
  display: flex;
  background-color: ${colors.background};
`;

export const AvatarInfo = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  padding-left: 100px;
  background-color: ${colors.primary};
`;

export const Info = styled.div`
  display: flex;
  flex: 1;
  margin: 24px;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.h2`
  font-family: ${fonts.Roboto};
  font-weight: 900;
  color: ${colors.secondaryLight};
`;

export const SubText = styled.h1`
  font-family: ${fonts.Roboto};
  font-weight: lighter;
  color: ${colors.white};
`;

export const Social = styled.div`
  display: flex;
  height: 50px;
  padding-left: 100px;
  margin-left: 24px;
  align-items: center;
  background-color: ${colors.secondary};
`;

export const SocialText = styled.h1`
  font-family: ${fonts.Roboto};
  font-weight: 700;
  color: ${colors.primary};
  margin-right: 8px;
  opacity: 0;
  transition: opacity 0.5s ease;
`;

export const IconWhatsapp = styled(RiWhatsappFill).attrs({ size: 32 })`
  color: ${colors.primary};
  margin-right: 16px;
  transition: transform 0.3s ease;
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${SocialText} {
      opacity: 1;
    }
    ${IconWhatsapp} {
      transform: scale(1.1);
    }
  }
`;

export const IconFacebook = styled(MdFacebook).attrs({ size: 32 })`
  color: ${colors.primary};
  margin-right: 16px;
  transition: transform 0.5s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const IconInstagram = styled(RiInstagramFill).attrs({ size: 32 })`
  color: ${colors.primary};
  margin-right: 16px;
  transition: transform 0.5s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const IconGooglePlay = styled(FaGooglePlay).attrs({ size: 32 })`
  color: ${colors.primary};
  margin-right: 16px;
  transition: transform 0.5s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

export const BodyLeft = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  max-width: 500px;
  max-height: 500px;
  min-width: 300px;
  min-height: 300px;
`;

export const Title = styled.h2`
  max-width: 50%;
  font-family: ${fonts.IndieFlower};
  color: ${colors.white};
  text-align: center;
  font-size: 5vw;
`;

export const Footer = styled.div`
  height: 25vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: end;

  background-image: url(${WAVES_IMG});
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;

export const Copyright = styled.h5`
  margin: 8px 64px;
  font-weight: lighter;
  cursor: pointer;
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingCircle = styled.div`
  display: inline-block;
  border: 4px solid ${colors.primaryLight};
  border-top: 4px solid ${colors.secondary};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spinAnimation} 1s linear infinite;
`;
