const colors = {
  // CORES DO PROJETO
  primaryDark: '#7B0000',
  primary: '#A91D20',
  primaryLight: '#D0443C',
  primaryLighter: '#FF8574',

  secondaryDark: '#977600',
  secondary: '#FFCF17',
  secondaryLight: '#FFC554',
  secondaryLighter: '#fff3c4',

  // CORES PADRÕES
  title: '#333132',
  text: '#4F4F4F',
  textWhite: '#fefefe',

  sucess: '#54C681',
  danger: '#FF7676',
  warning: '#F0AD4E',
  info: '#5BC0DE',
  background: '#F0F0F5',

  white: '#fff',
  lighter: '#eee',
  light: '#ddd',
  regular: '#999',
  boulder: '#777',
  dark: '#666',
  darker: '#333',
  black: '#000',

  transparent: 'transparent',
  darkTransparent: 'rgba(0, 0, 0, 0.6)',
  darkLighterTransparent: 'rgba(0, 0, 0, 0.1)',
  lighterWhiteTransparent: 'rgba(255, 255, 255, 0.2)',
  whiteTransparent: 'rgba(255, 255, 255, 0.5)',
  darkerWhiteTransparent: 'rgba(255, 255, 255, 0.8)',
};

export default colors;
