import { createGlobalStyle } from 'styled-components';

import fonts from './fonts';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body{
    background: #F0F0F5;
    -webkit-font-smoothing: antialiased;
  }

  html {
    scroll-behavior: smooth;
    user-select: none;
  }

  body, input, button, text, h1{
    font: 1rem ${fonts.Cabin}, sans-serif;
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 700;
  }

  button{
    cursor: pointer;
  }

  img{
    image-rendering: pixelated;
  }

`;
