// Modules
import React, { useCallback, useEffect, useState } from 'react';

// Assets
import backgroundPlayImg from '../../assets/backgroundPlay.svg';

// Styles
import {
  Animation,
  Container,
  Controls,
  GuitarPaletteImage,
  IconPause,
  IconPlay,
  PlayPauseButton,
  VolumeSlider,
  IconVolumeUp,
  IconVolumeDown,
  IconVolumeOff,
  IconVolumeMute,
  VolumeSliderContainer,
  VolumeSliderWrapper,
  Audio,
  Source,
  LoadingCircle,
} from './styles';

interface IProps {
  url_stream?: string;
  isLoading: boolean;
}

const Play: React.FC<IProps> = ({ isLoading, url_stream }) => {
  const [isPlaying, setPlaying] = useState(false);
  const [volume, setVolume] = useState(50);

  const loadAudio = useCallback(async () => {
    const AudioSRC: HTMLMediaElement | null = document.getElementById(
      'player',
    ) as HTMLMediaElement;

    if (!AudioSRC) return;

    AudioSRC.volume = volume / 100;
    await AudioSRC.play();

    setPlaying(true);
  }, [volume]);

  useEffect(() => {
    loadAudio();
  }, [loadAudio]);

  const handlePlayPause = () => {
    const PlayPause: HTMLMediaElement | null = document.getElementById(
      'player',
    ) as HTMLMediaElement;
    if (!PlayPause) return;

    if (!isPlaying) {
      PlayPause.play();
      setPlaying(true);
    } else {
      PlayPause.pause();
      setPlaying(false);
    }
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVolume(parseInt(event.target.value, 10));
  };

  const renderIconVolume = () => {
    if (volume >= 70) {
      return <IconVolumeUp />;
    }
    if (volume >= 30 && volume < 70) {
      return <IconVolumeDown />;
    }
    if (volume > 0 && volume < 30) {
      return <IconVolumeOff />;
    }
    return <IconVolumeMute />;
  };
  return (
    <Container>
      {!isLoading && (
        <Audio id="player" autoPlay>
          <Source src={url_stream} type="audio/mp3" />
        </Audio>
      )}
      <Animation>
        <GuitarPaletteImage src={backgroundPlayImg} alt="Player UAI FM" />

        <Controls>
          {!isLoading ? (
            <>
              <PlayPauseButton onClick={handlePlayPause}>
                <IconPlay isPlaying={isPlaying} />
                <IconPause isPlaying={isPlaying} />
              </PlayPauseButton>
              <VolumeSliderContainer>
                {renderIconVolume()}

                <VolumeSliderWrapper>
                  <VolumeSlider
                    type="range"
                    value={volume}
                    onChange={handleVolumeChange}
                  />
                </VolumeSliderWrapper>
              </VolumeSliderContainer>
            </>
          ) : (
            <LoadingCircle />
          )}
        </Controls>
      </Animation>
    </Container>
  );
};

export default Play;
