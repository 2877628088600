// Modules
import styled, { keyframes } from 'styled-components';

// Icons
import { FaPause, FaPlay } from 'react-icons/fa';
import {
  MdVolumeUp,
  MdVolumeDown,
  MdVolumeMute,
  MdVolumeOff,
} from 'react-icons/md';

// Styles
import colors from '../../styles/colors';

// Type
interface IIcon {
  isPlaying: boolean;
}

export const Audio = styled.audio``;

export const Source = styled.source``;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2% 5% 1%;
  overflow: hidden;
`;

export const Animation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5%;
`;

export const GuitarPaletteImage = styled.img`
  max-width: 250px;
  max-height: 250px;
  min-width: 150px;
  min-height: 150px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.5));
  transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);

  ${Animation}:hover & {
    transform: scale(1.05);
  }
`;

export const Controls = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const PlayPauseButton = styled.div`
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  width: 48px;
  height: 48px;
`;

export const IconPlay = styled(FaPlay).attrs({ size: 48 })<IIcon>`
  color: ${colors.white};
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out, filter 0.3s ease-in-out;

  opacity: ${props => (props.isPlaying ? 0 : 1)};
  pointer-events: ${props => (props.isPlaying ? 'none' : 'auto')};

  &:hover {
    filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.25));
  }
`;

export const IconPause = styled(FaPause).attrs({ size: 48 })<IIcon>`
  color: ${colors.white};
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out, filter 0.3s ease-in-out;

  opacity: ${props => (props.isPlaying ? 1 : 0)};
  pointer-events: ${props => (props.isPlaying ? 'auto' : 'none')};

  &:hover {
    filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.25));
  }
`;
export const VolumeSliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 130px;

  overflow: hidden;
  margin-top: 32px;
`;

export const IconVolumeUp = styled(MdVolumeUp).attrs({ size: 32 })`
  color: ${colors.white};
`;

export const IconVolumeDown = styled(MdVolumeDown).attrs({ size: 32 })`
  color: ${colors.white};
`;

export const IconVolumeOff = styled(MdVolumeMute).attrs({ size: 32 })`
  color: ${colors.white};
`;

export const IconVolumeMute = styled(MdVolumeOff).attrs({ size: 32 })`
  color: ${colors.white};
`;

export const VolumeSliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const VolumeSlider = styled.input`
  width: 100%;
  background: ${colors.white};
  border: none;
  height: 8px;
  border-radius: 2px;
  outline: none;
  margin-right: 8px;
  -webkit-appearance: none;
  transition: filter 0.3s ease-in-out;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: ${colors.secondary};
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.25);
    }
  }

  &:hover {
    filter: drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.25));
  }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingCircle = styled.div`
  display: inline-block;
  border: 4px solid ${colors.primaryLight};
  border-top: 4px solid ${colors.secondary};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spinAnimation} 1s linear infinite;
`;
